import React, { ComponentPropsWithoutRef, ForwardedRef } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

import { mq } from '@dop/shared/styleHelpers/mediaQueries';
import { focusStyle } from 'moduleAlias/project/src/styles/mixins/focus';
import { grijs, hemelblauw } from 'moduleAlias/project/src/styles/colors';
import { fontSize } from 'moduleAlias/project/src/styles/mixins/fontSize';

import { WasteRadioInput } from './ui/WasteRadio';
import { wasteMatrixTableBreakpoint } from './WasteMatrixTable';
import { RequiredProps } from '@/globals/helpers/typescript.helpers';

const darkenedColor = darken(0.07); //eslint-disable-line no-magic-numbers

export const WasteMatrixRadio = React.forwardRef(
	(
		{
			id,
			label,
			...inputProps
		}: Omit<
			RequiredProps<
				ComponentPropsWithoutRef<'input'>,
				'id' | 'value' | 'name' | 'onChange'
			>,
			'children'
		> & { label: string },
		ref: ForwardedRef<HTMLInputElement>,
	) => {
		return (
			<div
				css={`
					position: relative;
				`}
			>
				<WasteRadioInput ref={ref} type="radio" id={id} {...inputProps} />
				<label
					htmlFor={id}
					data-title={label}
					css={`
						line-height: 1.2;
						padding: 0.4em 0.8em 0.5em 0.8em;
						color: ${grijs.grijs6};
						background-color: ${grijs.lightest};
						border: 1px solid ${grijs.default};
						border-radius: 5px;
						text-align: center;
						${mq({ to: wasteMatrixTableBreakpoint })`
                            ${fontSize('small')};
						    padding-left: 0.5em;
						    padding-right: 0.5em;
                        `};
						${WasteRadioInput}:hover + & {
							background-color: ${darkenedColor(grijs.lightest)};
							color: ${darkenedColor(grijs.grijs6)};
							border-color: ${darkenedColor(grijs.default)};
						}
						${WasteRadioInput}:checked + & {
							font-weight: bold;
							background-color: ${hemelblauw.default};
							border-color: transparent;
							color: white;
						}
						${WasteRadioInput}:focus + & {
							${focusStyle};
						}
						&:before {
							// this increases the size of the labels as if it were selected (with bold text)
							// so it prevents pixel-jumping of the container
							display: block;
							content: attr(data-title);
							font-weight: bold;
							height: 1px;
							color: transparent;
							overflow: hidden;
							visibility: hidden;
						}
					`}
				>
					{label}
				</label>
			</div>
		);
	},
);

export const WasteMatrixRadioGroup = styled.div`
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: repeat(3, minmax(auto, 1fr));
	column-gap: 1.5rem;
	row-gap: 0.5rem;

	${mq({ to: 'smallMedium' })`
        grid-template-rows: repeat(3, minmax(auto, 1fr));
        grid-template-columns: auto;
    `};
`;
