import React, { ComponentPropsWithoutRef } from 'react';

import { hemelblauw } from 'moduleAlias/project/src/styles/colors';
import { cleanButton } from 'moduleAlias/project/src/styles/mixins/button';
import { useFocusOnValueChange } from '@dop/shared/hooks/useFocusOnMount';
import { IconInfo } from '@dop/ui-icons/svg/info';
import { SideOverlay } from '@dop/shared/components/sideOverlay/sideOverlay';
import { Stack } from '@dop/ui-primitives/layout/Stack';
import { Heading } from '@dop/ui-composites/typography/Heading';
import { MarkupTextBlock } from '@dop/ui-composites/typography/MarkupTextBlock';
import { HTMLContent } from '@/sections/markup/Markup.types';
import { WasteGuidePageData } from './wasteGuide.types';

const WasteItemInfoPopup = ({
	onClose,
	opened,
	id,
	title,
	description,
}: {
	onClose: () => void;
	opened: boolean;
	id: string;
	title: string;
	description: HTMLContent;
}) => {
	return (
		<SideOverlay
			onClose={onClose}
			opened={opened}
			id={id}
			aria-label={title}
			contentSlot={
				<Stack $gap={3}>
					<Heading level={2} as="h3">
						{title}
					</Heading>
					<MarkupTextBlock html={description.html} />
				</Stack>
			}
		/>
	);
};

export const WasteItemInfoPopupButton = ({
	slug,
	questionId,
	wastesMap,
	...buttonProps
}: {
	slug: string;
	questionId: string;
	wastesMap: WasteGuidePageData['wastes'];
} & ComponentPropsWithoutRef<'button'>) => {
	const id = `waste-item-info-popup-${slug}-question-${questionId}`;
	const [opened, setOpened] = React.useState(false);
	const focusRef = useFocusOnValueChange<boolean, HTMLButtonElement>(
		opened,
		true,
	);
	const wasteItem = wastesMap[slug];

	if (wasteItem == null) return null;

	const { title, description } = wasteItem;

	if (!description) return null;

	return (
		<React.Fragment>
			<button
				ref={focusRef}
				type="button"
				aria-expanded={opened}
				aria-controls={id}
				onClick={() => setOpened((opened) => !opened)}
				css={`
					${cleanButton};
					display: block;
					flex: none;
					width: 1.2rem;
					height: 1.2rem;
					margin-top: 0.15rem;
					margin-left: 0.05rem;
					color: ${hemelblauw.default};
				`}
				{...buttonProps}
			>
				<IconInfo
					role="img"
					aria-label={`Meer informatie over ${title}`}
					css={`
						width: 100%;
						height: 100%;
					`}
				/>
			</button>

			<WasteItemInfoPopup
				onClose={() => setOpened(false)}
				opened={opened}
				id={id}
				title={title}
				description={description}
			/>
		</React.Fragment>
	);
};
