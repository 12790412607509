import {
	goBackFromResults,
	goBackToStage,
	goToNextStage,
	startWasteGuide,
} from './wasteGuideStageReducer';
import {
	PayloadAction,
	bindActionCreators,
	createSlice,
} from '@reduxjs/toolkit';
import {
	LOT_SIZE_STAGE,
	WASTE_VOLUME_FOR_LARGE_LOT_STAGE,
	WASTE_VOLUME_FOR_MEDIUM_LOT_STAGE,
	WasteGuideState,
} from '../wasteGuide.types';
import { useMemo, useReducer } from 'react';

const wasteGuideSlice = createSlice({
	name: 'wasteGuide',
	initialState: (): WasteGuideState => {
		return {
			stages: [],
			lotSize: undefined,
			wasteVolumeForMediumLot: undefined,
			wasteVolumeForLargeLot: undefined,
			frequentWasteForMediumCompany: undefined,
			frequentWasteForLargeCompany: new Set(),
			incidentalWaste: new Set(),
			alwaysSeparateWaste: {},
		};
	},
	reducers: {
		wasteGuideStarted: (state) => {
			startWasteGuide(state);
		},
		questionSubmitted: (state) => {
			goToNextStage(state);
		},
		lotSizeChecked: (
			state,
			{ payload }: PayloadAction<WasteGuideState['lotSize']>,
		) => {
			state.lotSize = payload;

			goBackToStage(state, LOT_SIZE_STAGE);
		},
		wasteVolumeForMediumLotChecked: (
			state,
			{ payload }: PayloadAction<WasteGuideState['wasteVolumeForMediumLot']>,
		) => {
			state.wasteVolumeForMediumLot = payload;

			goBackToStage(state, WASTE_VOLUME_FOR_MEDIUM_LOT_STAGE);
		},
		wasteVolumeForLargeLotChecked: (
			state,
			{ payload }: PayloadAction<WasteGuideState['wasteVolumeForLargeLot']>,
		) => {
			state.wasteVolumeForLargeLot = payload;

			goBackToStage(state, WASTE_VOLUME_FOR_LARGE_LOT_STAGE);
		},
		frequentWasteForMediumCompanyChecked: (
			state,
			{
				payload,
			}: PayloadAction<WasteGuideState['frequentWasteForMediumCompany']>,
		) => {
			state.frequentWasteForMediumCompany = payload;

			goBackFromResults(state);
		},
		frequentWasteForLargeCompanyChecked: (
			state,
			{ payload }: PayloadAction<string>,
		) => {
			state.frequentWasteForLargeCompany.add(payload);

			goBackFromResults(state);
		},
		frequentWasteForLargeCompanyUnchecked: (
			state,
			{ payload }: PayloadAction<string>,
		) => {
			state.frequentWasteForLargeCompany.delete(payload);

			goBackFromResults(state);
		},
		incidentalWasteChecked: (state, { payload }: PayloadAction<string>) => {
			state.incidentalWaste.add(payload);

			goBackFromResults(state);
		},
		incidentalWasteUnchecked: (state, { payload }: PayloadAction<string>) => {
			state.incidentalWaste.delete(payload);

			goBackFromResults(state);
		},
		alwaysSeparateWasteChecked: (
			state,
			{ payload }: PayloadAction<{ slug: string; value: string }>,
		) => {
			state.alwaysSeparateWaste[payload.slug] = payload.value;

			goBackFromResults(state);
		},
	},
});

export type WasteGuideActions = typeof wasteGuideSlice.actions;

export const useWasteGuideReducer = () => {
	const [wasteGuideState, distpatchWasteGuide] = useReducer(
		wasteGuideSlice.reducer,
		wasteGuideSlice.getInitialState(),
	);

	const actions = useMemo(
		() =>
			bindActionCreators(
				wasteGuideSlice.actions,
				// Typescript will infer actions correctly this way
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				distpatchWasteGuide as any,
			),
		[],
	);

	return { wasteGuideState, actions };
};
