import React from 'react';

import { hasWindow } from '@dop/shared/helpers/windowDocument';

/**
 * Delay focusing a bit, otherwise on new page load the focus gets overwitten.
 */
const delayedFocus = (
	target: HTMLElement | null | undefined,
	options?: FocusOptions,
) => {
	const timer = setTimeout(() => {
		target?.focus?.(options);
	}, 20);

	return () => {
		clearTimeout(timer);
	};
};

/**
 * Delay focusing a bit, otherwise on new page load the scroll position gets overwitten.
 */
const delayedScrollIntoView = (
	target: HTMLElement | null | undefined,
	options?: ScrollIntoViewOptions,
) => {
	const timer = setTimeout(() => {
		target?.scrollIntoView?.(options);
	}, 100);

	return () => {
		clearTimeout(timer);
	};
};

export const useFocusOnMount = <
	FocusTargetElement extends HTMLElement,
>(): React.RefObject<FocusTargetElement> => {
	const focusRef = React.useRef<FocusTargetElement>(null);

	React.useEffect(() => {
		if (!hasWindow()) return;

		return delayedFocus(focusRef.current);
	}, []);

	return focusRef;
};

export const useReturnableFocusOnMount = <
	FocusTargetElement extends HTMLElement,
>(
	shouldFocus = true,
): React.RefObject<FocusTargetElement> => {
	const focusRef = React.useRef<FocusTargetElement>(null);
	const previousFocusRef = React.useRef<Element | null>();

	React.useEffect(() => {
		if (!hasWindow() || !shouldFocus) return undefined;

		previousFocusRef.current = document.activeElement;

		const cancelFocus = delayedFocus(focusRef.current);

		return () => {
			cancelFocus();
			if (previousFocusRef.current instanceof HTMLElement) {
				// Because this runs the EffectDestructor
				// this focus is never run on new page load
				// so it does not have to be delayed.
				previousFocusRef.current.focus();
			}
		};
	}, [shouldFocus]);

	return focusRef;
};

export const useFocusOnValueChange = <
	ValueType,
	FocusTargetElement extends HTMLElement,
>(
	value: ValueType,
	oldValue: ValueType,
): React.RefObject<FocusTargetElement> => {
	const focusRef = React.useRef<FocusTargetElement>(null);

	React.useEffect(() => {
		if (!hasWindow()) return undefined;

		const focusDOMNode = focusRef.current;

		return () => {
			if (value === oldValue && focusDOMNode != null) {
				// Because this runs the EffectDestructor
				// this focus is never run on new page load
				// so it does not have to be delayed.
				focusDOMNode.focus();
			}
		};
	}, [oldValue, value]);

	return focusRef;
};

export const useFocusWhen = <FocusTargetElement extends HTMLElement>(
	value: boolean,
): React.RefObject<FocusTargetElement> => {
	const focusRef = React.useRef<FocusTargetElement>(null);

	React.useEffect(() => {
		if (!hasWindow()) return;

		if (value) {
			return delayedFocus(focusRef.current);
		}
	}, [value]);

	return focusRef;
};

export const useScrollAndFocusOnMount = <
	ScrollTargetElement extends HTMLElement,
	FocusTargetElement extends HTMLElement,
>(): {
	scrollRef: React.RefObject<ScrollTargetElement>;
	focusRef: React.RefObject<FocusTargetElement>;
} => {
	const scrollRef = React.useRef<ScrollTargetElement>(null);
	const focusRef = React.useRef<FocusTargetElement>(null);
	React.useEffect(() => {
		if (!hasWindow()) return;

		let cancelFocus: (() => void) | undefined;
		let cancelScrollStart: (() => void) | undefined;

		if (focusRef.current) {
			cancelFocus = delayedFocus(focusRef.current, {
				preventScroll: scrollRef.current != null,
			});
		}
		if (scrollRef.current) {
			cancelScrollStart = delayedScrollIntoView(scrollRef.current, {
				behavior: 'smooth',
			});
		}

		return () => {
			cancelFocus?.();
			cancelScrollStart?.();
		};
	}, []);
	return { scrollRef, focusRef };
};
