import React from 'react';
import styled from 'styled-components';

import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { accAlwaysHide } from 'moduleAlias/project/src/styles/mixins/accHide';
import { focusStyle } from 'moduleAlias/project/src/styles/mixins/focus';
import { grijs, hemelblauw } from 'moduleAlias/project/src/styles/colors';

export const shouldFocusRadioInput = ({
	value,
	selection,
	index,
}: {
	value: string;
	selection?: string | null;
	index: number;
}) => {
	return selection != null ? value === selection : index === 0;
};

export const WasteRadioGroup = styled.div.attrs(() => ({
	role: 'radiogroup',
}))``;

export const WasteRadioInput = styled.input`
	${accAlwaysHide};
`;

type WasteRadioProps = {
	id: string;
	children: React.ReactNode;
	value: string;
	name: string;
	defaultChecked?: boolean;
	checked?: boolean;
	required?: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onInvalid: (event: React.FormEvent<HTMLInputElement>) => void;
};

export const WasteRadio = React.forwardRef(
	(
		{ id, children, ...inputProps }: WasteRadioProps,
		ref: React.ForwardedRef<HTMLInputElement>,
	) => {
		return (
			<div
				css={`
					flex: auto 0 1;
					position: relative;
				`}
			>
				<WasteRadioInput ref={ref} type="radio" id={id} {...inputProps} />
				<label
					htmlFor={id}
					css={`
						display: flex;
						align-items: flex-start;
						line-height: 1.2;
						width: fit-content;
						padding: 0.15rem 0.5rem 0.15rem 0.15rem;

						${WasteRadioInput}:checked + & {
							background-color: ${hemelblauw.lighter};
							color: ${grijs.darkest};
							font-weight: bold;
						}
						${WasteRadioInput}:focus + & {
							${focusStyle};
						}

						&:before {
							content: '';
							box-sizing: border-box;
							display: block;
							flex: none;
							border-radius: 999px;
							border: 1px solid currentColor;
							height: 1em;
							width: 1em;
							margin: 0.1em 1em 0.1em 0.1em;
							background-clip: content-box;
							padding: 0.1em;
						}

						${WasteRadioInput}:checked + &:before {
							color: ${hemelblauw.default};
							background-color: currentColor;
						}
					`}
					{...addTestSelector(`uiRadio-${id}`)}
				>
					{children}
				</label>
			</div>
		);
	},
);
